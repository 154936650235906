import React, { FC } from 'react'

import { Text } from 'mf-ui-components'

import { Container, Title } from './DescriptionBox.styles'

type Props = {
  title: string
  description?: string
}

export const DescriptionBox: FC<Props> = ({ title, description = '' }) => (
  <Container>
    <Title>{title}</Title>
    <Text styleName="bodyRegular" el="p">
      <span
        dangerouslySetInnerHTML={{ __html: description }}
        style={{
          opacity: 0.9,
        }}
      />
    </Text>
  </Container>
)

import styled from 'styled-components'

import { GRID_UNIT } from 'mf-ui-components'

export const TopBannerContainer = styled.div<{ backgroundColour: string }>`
  width: 100%;
  background-color: ${({ backgroundColour }) => backgroundColour};
  padding: ${GRID_UNIT * 2}px ${GRID_UNIT * 4}px;
  z-index: 100;
  position: sticky;
  text-align: center;
  top: 0;

  span {
    color: ${({ theme }) => theme.tokens.accent.token.secondary.regular};
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;

    a {
      color: ${({ theme }) => theme.tokens.accent.token.secondary.regular};
      text-decoration: underline;
    }
  }
`

import React, { useState } from 'react'

import { CloseButton } from './CloseButton'
import { TopBannerContainer } from './TopBanner.styles'

type Props = {
  htmlContent?: string
  backgroundColour: string
  hideAfter?: string
}

/**
 * A banner that can be displayed at the top of every page on the website.
 *
 * @param {htmlContent} string - (OPTIONAL) The HTML content to display in the banner. If omitted, the banner will not be displayed.
 * @param {backgroundColour} string - The background color of the banner.
 * @param {hideAfter} Date - (OPTIONAL) The date (in `Y-m-d H:i:s` format) after which the banner should be hidden. If omitted, the banner will always be displayed, given that the `text` field is not empty.
 */
export const TopBanner = ({
  htmlContent,
  backgroundColour,
  hideAfter,
}: Props) => {
  const [isClosed, setClosed] = useState<boolean>(false)

  // NOTE: `hideAfter` is a string in `Y-m-d H:i:s` format, which is not
  // guaranteed to be a valid format for the `Date` constructor on all runtimes.
  // We therefore convert it to `Y-m-dTH:i:s` to make it ISO8601-compliant.
  const shouldDisplay: boolean =
    !isClosed &&
    !!htmlContent?.length &&
    (!hideAfter || new Date(hideAfter.replace(' ', 'T')) > new Date())

  if (shouldDisplay)
    return (
      <TopBannerContainer id="top-banner" backgroundColour={backgroundColour}> 
        <span
          dangerouslySetInnerHTML={{
            __html: htmlContent!,
          }}
        />

        <CloseButton handleClick={() => setClosed(true)} />
      </TopBannerContainer>
    )

  return null
}

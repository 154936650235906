import { Link, withPrefix } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'

import { fromBreakpoint, untilBreakpoint } from '../utils/style/breakpoint'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  ${untilBreakpoint('lg')} {
    margin-left: 10px;
  }
`

const WordMark = styled.div`
  display: none;
  align-items: center;

  ${fromBreakpoint('xl')} {
    display: flex;
  }

  img {
    height: 35px;
    width: 190px;
  }
`
const Icon = styled.div`
  display: flex;
  align-items: center;

  ${fromBreakpoint('xl')} {
    display: none;
  }
`

export const SiteLogo: FC = () => (
  <Wrapper className="logo">
    <Link to="/">
      <WordMark className="mfm-wordmark">
        <img
          alt="Moneyfarm Logo"
          src={withPrefix('/moneyfarm-logotype-2023.svg')}
        />
      </WordMark>
      <Icon className="mfm-icon">
        <img
          alt="Moneyfarm Logo"
          src={withPrefix('/moneyfarm-logomark-2023.svg')}
        />
      </Icon>
    </Link>
  </Wrapper>
)

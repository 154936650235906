import classnames from 'classnames'
import React, { FC } from 'react'

import { Grid } from 'mf-ui-components'

import { MenuNode } from '../types'
import { NavigationBarLink } from './NavigationBarLink'
import { DescriptionBox } from './DescriptionBox'
import { MenuEntry } from './MenuEntry'
import {
  EntriesContainer,
  MenuItem,
  Dropdown,
  Triangle,
  Rectangle,
} from './DesktopMenuItem.styles'

const isTel = (url: string) => url.startsWith('tel://')

type Props = {
  node: MenuNode
}

export const DesktopMenuItem: FC<Props> = ({ node }) => {
  const childrenCount = node.childItems.nodes.length
  const hasChildren = childrenCount > 0
  const hasNestedChildren =
    hasChildren &&
    node.childItems.nodes.some(({ childItems }) => !!childItems?.nodes?.length)

  return (
    <MenuItem className={classnames([{ hasChildren, tel: isTel(node.url) }])}>
      <NavigationBarLink node={node} hasChildren={hasChildren} />

      {hasChildren && (
        <>
          <Rectangle className="hover-zone" />
          <Triangle className="hover-zone" />

          <Dropdown className="child-menu">
            <Grid flow="row" gap="md" alignItems="stretch">
              <DescriptionBox
                title={node.label}
                description={node.title || node.description}
              />

              <EntriesContainer hasNestedChildren={hasNestedChildren} childrenCount={childrenCount}>
                {node.childItems.nodes.map((child, index) => (
                  <MenuEntry key={index} {...child} />
                ))}
              </EntriesContainer>
            </Grid>
          </Dropdown>
        </>
      )}
    </MenuItem>
  )
}
